<template>
  <div>
    <b-alert
      variant="danger"
      show
      v-if="compleatePaymentStart === true && initObj !== null && !subscription"
    >
      <h4 class="alert-heading">
        {{ $t('alert') }}
      </h4>
      <div class="alert-body">
        <b-row>
          <b-col md="6">
            <span class="h2 text-danger">{{
              $t('youShouldCompleatePayment')
            }}</span>
          </b-col>
          <b-col md="6" class="text-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              @click="
                () => {
                  saveInit();
                }
              "
              variant="primary"
            >
              {{ $t('compleatePayment') }}
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-alert>
    <b-card
      no-body
      class="card-apply-job"
      v-if="subscription.arabicName && !userData.isAdmin"
    >
      <b-card-header class="pb-1">
        <b-media no-body>
          <b-media-body>
            <h2 class="text-primary">
              {{ subscription.arabicName }}
            </h2>
            <strong class="text-muted">{{ $t('subscrptionDate') }}</strong>
            <small class="ml-2" style="unicode-bidi: plaintext;">{{
              getDate(subscription.startDate)
            }}</small>
          </b-media-body>
        </b-media>
        <a
          :href="`https://${subscription.tenants.domainName}.eduprosys.net/#/`"
          target="_blank"
          rel="noopener noreferrer"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
          >
            دخول
          </b-button>
        </a>
      </b-card-header>
      <b-card-body>
        <h3 class="apply-job-title">
          اهلا بك شريكنا العزيز
        </h3>
        <b-card-text
          class="mb-2 h4"
        >
          برنامج اديو برو لإدارة المدارس كل ما تحتاجة في برنامج واحد نسعى دائما لخدمة
          عملائنا لاننا نؤمن بان عملائنا هم راس مالنا الحقيقي
        </b-card-text>
      </b-card-body>
      <b-card-footer class="bg-light-primary">
        <div>
          <b-badge variant="light-primary" style="font-size: 19px;" pill>
            موعد انتهاء الباقه
          </b-badge>
          <h4 class="d-inline mr-25">
            {{ getDate(subscription.endDate) }}
          </h4>
        </div>
      </b-card-footer>
    </b-card>
    <b-card>
      <b-card-header class="pb-4">
        <h2 class="mb-0">
          {{ $t('tenants') }}
        </h2>
      </b-card-header>
      <b-table
        ref="tenantsTable"
        :items="tenants"
        responsive
        :fields="tenantsColumns"
      >
        <template #head(actions)>
          <span />
        </template>
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`invoice-row-${data.item.id}-prev-icon`"
              icon="EyeIcon"
              data-action-type="preview"
              class="mx-1 clickable "
              @click="
                () => {
                  getPayments(data.item.id);
                  getSupscription(data.item.id);
                }
              "
            />
            <b-tooltip
              :title="$t('preview')"
              placement="bottom"
              :target="`invoice-row-${data.item.id}-prev-icon`"
            />
          </div>
        </template>
      </b-table>
    </b-card>
    <hr />
    <b-card>
      <b-card-header class="pb-1">
        <h2 class="mb-0">
          {{ $t('Payments') }}
        </h2>
      </b-card-header>
      <g-table
        ref="paymentsTable"
        :items="items"
        :columns="tableColumns"
        :noAction="true"
        perPage="15"
        :totalRows="totalRows"
        :createButton="{ visiable: false }"
        :searchInput="{ visiable: false }"
      >
      </g-table>
    </b-card>
    <b-modal
      no-close-on-backdrop
      ref="payForm-modal"
      cancel-variant="outline-secondary"
      centered
      style="direction: ltr;"
      size="lg"
      hide-footer
      :title="$t('pay')"
    >
      <h2 class="text-center mb-2">
        <span class="font-weight-bolder text-nowrap">
          {{ $t('net') }}
        </span>
        : {{ fraction(totalNet) }}
      </h2>
      <form
        style="direction: ltr"
        :action="prodctionUrl"
        class="paymentWidgets"
        data-brands="VISA MASTER AMEX"
      ></form>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button class="mx-1" variant="secondary" @click="closeModal">
            {{ $t('Close') }}
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
// import GTable from '@/pages/Shared/Table.vue';
import {
  BCard,
  BCardHeader,
  BMedia,
  BMediaBody,
  BBadge,
  BCardText,
  BCardBody,
  BButton,
} from 'bootstrap-vue';
import GTable from '@/pages/Shared/Table.vue';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    BCard,
    BButton,
    BBadge,
    BCardHeader,
    BMedia,
    BMediaBody,
    BCardText,
    BCardBody,
    GTable,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      items: [],
      tenants: [],
      subscription: {
        arabicName: '',
      },
      plans: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 15,
      checkOutId: JSON.parse(localStorage.getItem('CHECKOUT_ID')),
      userData: JSON.parse(localStorage.getItem('@USER_PROFILE')),
      initObj: JSON.parse(sessionStorage.getItem('INIT_OBJ')),
      totalNet: JSON.parse(sessionStorage.getItem('TOTAL')),
      compleatePaymentStart: false,
      prodctionUrl: `${document.location.origin}/payments`,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'transactionDateUtc',
          label: this.$t('date'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.fromUTC(item.transactionDateUtc);
          },
        },
        {
          key: 'total',
          label: this.$t('net'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.fraction(item.total);
          },
        },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.status === 0) {
              return `<span class="badge bg-warning bg-darken-1">${this.$t(
                'inProgress'
              )}</span>`;
            }
            if (item.status === 1) {
              return `<span class="badge bg-success bg-darken-1">${this.$t(
                'complete'
              )}</span>`
            }
            return `<span class="badge badge-danger">${this.$t(
              'paymentNotCompleate'
            )}</span>`;
          },
        },
      ];
    },
    tenantsColumns() {
      return [
        { key: 'domainName', label: this.$t('domin'), sortable: true },
        { key: 'name', label: this.$t('tenatName'), sortable: true },
        {
          key: 'registrationDateUtc',
          label: this.$t('subscrptionDate'),
          sortable: true,
          formatter: (key, value, item) => this.getDate(item.registrationDateUtc),
        },
        { key: 'actions' },
      ];
    },
  },
  beforeMount() {
    // this.$router.go(0)
  },
  mounted() {
    if (this.initObj !== null && !this.subscription) {
      this.compleatePaymentStart = true;
    }
    // if (localStorage.getItem('@ACCESS_TOKEN') && this.initObj !== null) {
    //   this.$router.push({ name: 'calculations' })
    // }
    if (this.checkOutId !== null) {
      this.compleatePayment();
    }
    this.getTenants();
  },
  methods: {
    saveInit() {
      this.initObj.tenantId = this.tenants[0].id;
      this.initObj.clientId = this.userData.clientId;
      this.create({ url: 'Payment/init', data: this.initObj })
        .then((data) => {
          this.openModal(data);
          localStorage.CHECKOUT_ID = JSON.stringify(data);
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
        });
    },
    openModal(data) {
      this.$refs['payForm-modal'].show();
      const paymentWidgets = document.createElement('script');
      paymentWidgets.setAttribute(
        'src',
        `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${data}`
      );
      document.head.appendChild(paymentWidgets);
    },
    closeModal() {
      this.$refs['payForm-modal'].hide();
    },
    getSupscription(_id) {
      this.get({ url: 'Clients/Subscription' }).then((data) => {
        this.subscription = data;
        this.subscription.arabicName = data.plans.arabicName;
      });
    },
    getTenants() {
      this.get({ url: 'Clients/Tenant' }).then((data) => {
        this.tenants = [];
        this.tenants.push(data);
        this.getPayments(this.tenants[0].id);
        this.getSupscription(this.tenants[0].id);
      });
    },
    openLinkErp(domainName) {
      window.location.href = `https://${domainName}.plugin-erp.com/#/`;
    },
    getPayments(_id) {
      this.get({ url: `Payment?tenantId=${_id}` }).then((data) => {
        this.items = data;
        if (data.length) {
          this.compleatePaymentStart = false;
        }
      });
    },
    compleatePayment() {
      this.create({ url: `Payment/complete?refno=${this.checkOutId}` })
        .then((data) => {
          if (data === 0) {
            this.doneAlert({ text: this.$t('paymentInProgress') });
          } else if (data === 1) {
            this.doneAlert({ text: this.$t('paymentIsDone') });
          } else {
            this.doneAlert({ text: this.$t('paymentIsFaild'), type: 'error' });
          }
          localStorage.removeItem('CHECKOUT_ID');
          this.getTenants();
        })
        .catch(({ data }) => {
          this.doneAlert({ text: this.$t(data.detail), type: 'error' });
          localStorage.removeItem('CHECKOUT_ID');
        });
    },
  },
};
</script>
