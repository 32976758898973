export default [
  {
    dependancies: [],
    name: 'Home',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewDashBoard' },
    ],
    title: 'Home',
  },
  {
    dependancies: [],
    name: 'currentUsers',
    roles: [
      { arabicName: 'البيانات الرئيسية', englishName: 'mainData', id: 'viewCurrentClients' },
      { arabicName: 'تغيير نهايه الإشتراك', englishName: 'changeSubscriptionEndDate', id: 'changeSubscriptionEndDate' },
      { arabicName: 'تغيير باسورد الأدمن', englishName: 'changeAdminPassword', id: 'changeAdminPassword' },
      { arabicName: 'نقل دليل الحسابات', englishName: 'transfareAccounts', id: 'transferAccounts' },
      { arabicName: 'تفعيل المخازن', englishName: 'activateStores', id: 'activateStores' },
    ],
    title: 'current-users',
  },
  {
    dependancies: [],
    name: 'notActiveUsers',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewPendingClients' },
      { arabicName: 'تفعيل', englishName: 'active', id: 'activatePendingClients' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deletePendingClients' },
    ],
    title: 'notActive-users',
  },
  {
    dependancies: [],
    name: 'pricingPolicy',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewPricingPolicy' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addPricingPolicy' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editPricingPolicy' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deletePricingPolicy' },
    ],
    title: 'pricing-policy',
  },
  {
    dependancies: [],
    name: 'clientInformation',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewClientInformation' },
    ],
    title: 'clientInformation',
  },
  {
    dependancies: [],
    name: 'plans',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewPlans' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addPlans' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deletePlans' },
    ],
    title: 'plans',
  },
  {
    dependancies: [],
    name: 'editPlan',
    roles: [
      { arabicName: 'تعديل الباقة', englishName: 'updatePlan', id: 'editPlan' },
      { arabicName: 'تعديل فئة الباقة', englishName: 'upgradePlan', id: 'upgradePlan' },
    ],
    title: 'editPlan',
  },
  {
    dependancies: [],
    name: 'discountCode',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewDiscountCode' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addDiscountCode' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editDiscountCode' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteDiscountCode' },
    ],
    title: 'discount-code',
  },
  {
    dependancies: [],
    name: 'fiscalYear',
    roles: [
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editFiscalYear' },
    ],
    title: 'fiscalYear',
  },
  {
    dependancies: [],
    name: 'adminRegester',
    roles: [
      { arabicName: 'تسجيل البيانات', englishName: 'dataSaved', id: 'registerNewClient' },
      { arabicName: 'تجديد الترخيص لمدة عام', englishName: 'renewLincesForOneYear', id: 'renewLicenses' },
    ],
    title: 'adminRegester',
  },
  {
    dependancies: [],
    name: 'users',
    roles: [
      { arabicName: 'عرض', englishName: ' View', id: 'viewUsers' },
      { arabicName: 'إضافة', englishName: ' Add', id: 'addUsers' },
      { arabicName: 'تعديل', englishName: ' Edit', id: 'editUsers' },
      { arabicName: 'حذف', englishName: ' Delete', id: 'deleteUsers' },
    ],
    title: 'users',
  },
  {
    dependancies: [],
    name: 'custom-report',
    roles: [
      { arabicName: 'إضافة', englishName: ' Add', id: 'addCustomReports' },
      { arabicName: 'تنزيل', englishName: ' Download', id: 'downloadCustomReports' },
    ],
    title: 'custom-report',
  },
];
