<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.tenantId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="domin"
              field="select"
              name="tenant"
              rules="required"
              :options="tenants"
              label="domainName"
              @change="
                (v) => {
                  getTenantBranches(v.id);
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.tenantId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="companyName"
              field="select"
              name="companyName"
              :options="tenants"
              disabled
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.branchId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="branch"
              field="select"
              name="branch"
              rules="required"
              :options="branches"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <g-field
            id="reportName"
            label-text="reportName"
            :value.sync="selectedItem.reportName"
            class="mb-0"
            :placeholder="$t('reportName')"
            name="reportName"
          />
        </b-col>
        <b-col md="8">
          <div style="margin-top: 2.3rem !important;">
            <input
              type="file"
              class="excel-upload-input"
              @change="handleFileUpload($event)"
              accept=".rdl"
            />
            <span v-if="file" class="bg-light-success px-3"
              >{{ $t('importSuccessfully') }} {{ file.name }}</span
            >
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            :disabled="
              !selectedItem.tenantId || !selectedItem.attachmentBase64Content
            "
            v-permission="'addCustomReports'"
            data-action-type="changeSerial"
          >
            {{ $t('uploadNewReport') }}
          </b-button>
          <b-button
            @click="downloadReport"
            :disabled="!selectedItem.tenantId || !selectedItem.reportName"
            class="mx-1"
            variant="warning"
            v-permission="'downloadCustomReports'"
            data-action-type="changeSerial"
          >
            {{ $t('downloadCurrentReport') }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
import saveAs from 'file-saver';

export default {
  data() {
    return {
      file: '',
      selectedItem: {
        reportName: '',
        tenantId: null,
        branchId: null,
        reportContent: '',
        // version: null,
        createdAtUtc: this.today,
        updatedAtUtc: this.today,
        attachmentBase64Content: '',
        attachmentFileExtension: '',
      },
      tenants: [],
      branches: [],
    };
  },
  mounted() {
    this.selectedItem.createdAtUtc = this.convertToLocalTime(this.today);
    this.getTenants();
  },
  methods: {
    getTenants() {
      this.get({ url: 'Tenants' }).then((data) => {
        this.tenants = data;
      });
    },
    getTenantBranches(tenantId) {
      this.get({ url: `Tenants/${tenantId}/branch` }).then((data) => {
        data.unshift({
          id: null,
          arabicName: 'عام علي كل الفروع',
        });
        this.branches = data;
      });
    },
    downloadReport() {
      this.get({
        url: this.selectedItem.branchId
          ? `CustomReports?TenantId=${this.selectedItem.tenantId}&BranchId=${this.selectedItem.branchId}&ReportName=${this.selectedItem.reportName}.rdl`
          : `CustomReports?TenantId=${this.selectedItem.tenantId}&ReportName=${this.selectedItem.reportName}.rdl`,
        responseType: 'arraybuffer',
      })
        .then((data) => {
          saveAs(
            new Blob([data], {
              type:
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            }),
            `${this.selectedItem.reportName}.rdl`
          );
        })
        .catch(() => {
          this.doneAlert({
            text: this.$t('لا يحتوي على تقرير معدل'),
            type: 'error',
          });
        });
    },
    save() {
      this.create({ url: 'CustomReports', data: this.selectedItem })
        .then(() => {
          this.doneAlert({ text: this.$t('savedSuccessfully') });
          this.file = '';
          const fileInput = document.querySelector('.excel-upload-input');
          fileInput.value = '';
          this.selectedItem.attachmentFileExtension = '';
          this.selectedItem.attachmentBase64Content = '';
          this.selectedItem.reportContent = '';
        })
        .catch((data) => {
          this.doneAlert({ text: data.detail, type: 'error' });
        });
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    handleFileUpload(event) {
      this.file = event.target.files[0];
      const fileSize = this.file.size / 1024 / 1024;
      this.selectedItem.attachmentFileExtension = this.file.name
        .split('.')
        .pop();
      this.toBase64(this.file).then((file1) => {
        this.selectedItem.attachmentBase64Content = file1.split(',').pop();
      });
      this.selectedItem.reportContent = URL.createObjectURL(this.file);
      if (!this.isValidType(this.file)) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.reportContent = '';
        this.doneAlert({
          text: this.$t('Only supports upload .rdl'),
          type: 'error',
        });
        this.file = '';
        return false;
      }
      if (this.isValidType(this.file) && fileSize > 5) {
        this.file = '';
        this.selectedItem.attachmentFileExtension = '';
        this.selectedItem.attachmentBase64Content = '';
        this.selectedItem.reportContent = '';
        this.doneAlert({
          text: this.$t('fileSizeExceedsFiveMiB'),
          type: 'error',
        });
        this.file = '';
        return false;
      }
    },
    isValidType(file) {
      return /\.(rdl)$/.test(file.name);
    },
  },
};
</script>

<style></style>
