<template>
<div>
  <b-card>
      <!-- <b-card-header class="pb-1"> -->
        <b-row>
          <b-col md="3">
            <b-form-group>
              <g-field
                :value.sync="tenantId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="tenants"
                field="select"
                name="tenants"
                :clearable="false"
                :options="tenants"
                label="name"
                @change="() => {
                  getPayments(tenantId)
                }"
              />
            </b-form-group>
          </b-col>
        </b-row>
      <!-- </b-card-header> -->
      <!-- <b-table
        :items="items"
        :fields="tableColumns"
        primary-key="id"
        show-empty
        striped
        hover
        fixed
        class="position-relative"
        :per-page="perPage"
        :current-page="currentPage"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :sort-direction="sortDirection"
        :empty-text="$t('noMatchingRecordsFound')"
      >
      <template #cell(status)="data">
        <div class="text-nowrap">
          <span class="badge bg-warning bg-darken-1" v-if="data.item.status === 0">{{this.$t('inProgress')}}</span>
          <span class="badge bg-success bg-darken-1" v-if="data.item.status === 1">{{this.$t('complete')}}</span>
          <span class="badge bg-danger bg-darken-1" v-if="data.item.status === 1">{{this.$t('paymentNotCompleate')}}</span>
        </div>
      </template>
      </b-table> -->
      <g-table
        ref="paymentsTable"
        :items="items"
        :columns="tableColumns"
        :noAction="true"
        perPage="25"
        :totalRows="totalRows"
        :sort-direction="sortDirection"
        :sort-desc.sync="isSortDirDesc"
        :sort-by.sync="sortBy"
        :createButton="{ visiable: false }"
        :searchInput="{ visiable: false }"
      >
      </g-table>
    </b-card>
  </div>
</template>

<script>
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
   data() {
    return {
      items: [],
      tenants: [],
      tenantId: 0,
      totalRows: 0,
      currentPage: 1,
      sortBy: 'id',
      sortDirection: 'asc',
      isSortDirDesc: true,
      perPage: 25,
      userData: JSON.parse(localStorage.getItem('@USER_PROFILE')),
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'transactionDateUtc',
          label: this.$t('date'),
          sortable: true,
          formatter: (key, value, item) => this.getDate(item.transactionDateUtc),
        },
        {
          key: 'total',
          label:
          this.$t('net'),
          sortable: true,
          formatter: (key, value, item) => {
            return this.fraction(item.total)
          }
        },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.status === 0) return `<span class="badge bg-warning bg-darken-1">${this.$t('inProgress')}</span>`;
            if (item.status === 1) return `<span class="badge bg-success bg-darken-1">${this.$t('complete')}</span>`;
            return `<span class="badge badge-danger">${this.$t('paymentNotCompleate')}</span>`;
          },
        },
      ];
    },
  },
   mounted() {
    this.getTenants();
  },
  methods: {
    getTenants() {
      this.get({ url: 'Clients/Tenant' }).then(
        (data) => {
          this.tenants.push(data);
          this.getPayments(this.tenants[0].id);
          this.tenantId = this.tenants[0].id
        }
      );
    },
    getPayments(_id) {
      this.get({ url: `Payment?tenantId=${_id}` }).then((data) => {
        this.items = data;
      });
    },
  },
}
</script>
