import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils';
import { canNavigate } from '@/libs/acl/routeProtection';

const profile = JSON.parse(window.localStorage.getItem('@USER_PROFILE') || '{}');
const prodctionUrl = document.location.host.indexOf('/admin');

Vue.use(VueRouter);

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/pages/Dashboard/Edit.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/payments',
      name: 'payments',
      component: () => import('@/pages/Payments/list.vue'),
      meta: {
        pageTitle: 'Payments',
        breadcrumb: [
          {
            text: 'Payments',
            active: true,
          },
        ],
      },
    },
    {
      path: '/perssonalAccount',
      name: 'perssonalAccount',
      component: () => import('@/pages/Settings/profile/Edit.vue'),
      meta: {
        pageTitle: 'perssonalAccount',
        breadcrumb: [
          {
            text: 'perssonalAccount',
            active: true,
          },
        ],
      },
    },
    {
      path: '/calculations',
      name: 'calculations',
      component: () => import('@/pages/Calculations/Edit.vue'),
      // meta: { layout: 'full' },
      meta: {
        pageTitle: 'calculations',
        breadcrumb: [
          {
            text: 'calculations',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/my-action-plan',
    //   name: 'my-action-plan',
    //   component: () => import('@/pages/admin/ActionPlans/board.vue'),
    //   meta: {
    //     pageTitle: 'action-plan',
    //     breadcrumb: [{ text: 'action-plan', active: true }],
    //     permission: '',
    //   },
    // },
    {
      path: '/start-calc/:id',
      name: 'start-calc',
      component: () => import('@/pages/Calculations/Edit.vue'),
      meta: { layout: 'full' }
    },
    {
      path: '/activate',
      name: 'activate',
      component: () => import('@/pages/validateEmail/Edit.vue'),
      meta: { layout: 'full' },
      props: true
    },
    {
      path: '/change-password',
      name: 'change-password',
      component: () => import('@/pages/Settings/ChangePassword/Edit.vue'),
      meta: {
        pageTitle: 'changePassword',
        breadcrumb: [{ text: 'changePassword', active: true }],
      },
    },
    {
      path: '/second-page',
      name: 'second-page',
      component: () => import('@/views/SecondPage.vue'),
      meta: {
        pageTitle: 'Second Page',
        breadcrumb: [
          {
            text: 'Second Page',
            active: true,
          },
        ],
      },
    },
    // {
    //   path: '/ForgetPassword',
    //   name: 'forget-password',
    //   component: () => import('@/pages/UserAccount/ForgotPassword.vue'),
    //   meta: { layout: 'full' },
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/pages/UserAccount/Login.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/pages/UserAccount/register.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: { layout: 'full' },
    },
    //  ---------------------- admin route -------------------------- \\
    {
      path: '/admin/login',
      name: 'admin-login',
      component: () => import('@/pages/admin/login/login.vue'),
      meta: { layout: 'full' },
    },
    {
      path: '/admin-home',
      name: 'admin-home',
      component: () => import('@/pages/admin/home/Edit.vue'),
      meta: {
        pageTitle: 'admin-home',
        breadcrumb: [
          {
            text: 'admin-home',
            active: true,
          },
        ],
        permission: '',
      },
    },
    {
      path: '/admin/renewLicense',
      name: 'renewLicense',
      component: () => import('@/pages/admin/register/Renew.vue'),
      meta: {
        pageTitle: 'renewLicense',
        breadcrumb: [{ text: 'renewLicense', active: true }],
        permission: 'renewLicense',
      },
    },
    {
      path: '/admin/plans',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'plans',
          component: () => import('@/pages/admin/plans/List.vue'),
          meta: {
            pageTitle: 'plans',
            breadcrumb: [{ text: 'plans', active: true }],
            permission: 'viewPlans',
          },
        },
        {
          path: 'new',
          name: 'plan-new',
          component: () => import('@/pages/admin/plans/Edit.vue'),
          meta: {
            pageTitle: 'plan-new',
            breadcrumb: [
              { text: 'plans', active: true },
            ],
            permission: 'addPlans',
          },
        },
        {
          path: 'edit/:id',
          name: 'plan-edit',
          props: true,
          component: () => import('@/pages/admin/plans/Edit.vue'),
          meta: {
            pageTitle: 'plan-edit',
            breadcrumb: [
              { text: 'plans', active: true },
            ],
            permission: 'editPlans',
          },
        },
      ],
    },
    {
      path: '/admin/discount-code',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'discount-code',
          component: () => import('@/pages/admin/discountCode/List.vue'),
          meta: {
            pageTitle: 'discount-code',
            breadcrumb: [{ text: 'discount-code', active: true }],
            permission: 'viewDiscountCode',
          },
        },
        {
          path: 'new',
          name: 'discountCode-new',
          component: () => import('@/pages/admin/discountCode/Edit.vue'),
          meta: {
            pageTitle: 'discountCode-new',
            breadcrumb: [
              { text: 'discount-code', active: true },
            ],
            permission: 'addDiscountCode',
          },
        },
        {
          path: 'edit/:id',
          name: 'discountCode-edit',
          props: true,
          component: () => import('@/pages/admin/discountCode/Edit.vue'),
          meta: {
            pageTitle: 'discountCode-edit',
            breadcrumb: [
              { text: 'discount-code', active: true },
            ],
            permission: 'editDiscountCode',
          },
        },
      ],
    },
    {
      path: '/admin/pricing-policy',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'pricing-policy',
          component: () => import('@/pages/admin/pricingPolicy/List.vue'),
          meta: {
            pageTitle: 'pricing-policy',
            breadcrumb: [{ text: 'pricing-policy', active: true }],
            permission: 'viewPricingPolicy',
          },
        },
        {
          path: 'new',
          name: 'pricingPolicy-new',
          component: () => import('@/pages/admin/pricingPolicy/Edit.vue'),
          meta: {
            pageTitle: 'pricingPolicy-new',
            breadcrumb: [
              { text: 'pricing-policy', active: true },
            ],
            permission: 'addPricingPolicy',
          },
        },
        {
          path: 'edit/:id',
          name: 'pricingPolicy-edit',
          props: true,
          component: () => import('@/pages/admin/pricingPolicy/Edit.vue'),
          meta: {
            pageTitle: 'pricingPolicy-edit',
            breadcrumb: [
              { text: 'pricing-policy', active: true },
            ],
            permission: 'editPricingPolicy',
          },
        },
      ],
    },
    // {
    //   path: '/admin/sms-config',
    //   component: () => import('@/layouts/navigationIndex.vue'),
    //   children: [
    //     {
    //       path: '',
    //       name: 'sms-config',
    //       component: () => import('@/pages/admin/SMSConfigurations/List.vue'),
    //       meta: {
    //         pageTitle: 'sms-config',
    //         breadcrumb: [{ text: 'sms-config', active: true }],
    //       },
    //     },
    //   ],
    // },
    {
      path: '/admin/current-users',
      name: 'current-users',
      component: () => import('@/pages/admin/currentUsers/List.vue'),
      meta: {
        pageTitle: 'current-users',
        breadcrumb: [
          {
            text: 'current-users',
            active: true,
          },
        ],
        permission: '',
      },
    },
    // {
    //   path: '/admin/change-serial',
    //   name: 'change-serial',
    //   component: () => import('@/pages/admin/ChangeSerials/Edit.vue'),
    //   meta: {
    //     pageTitle: 'change-serial',
    //     breadcrumb: [
    //       {
    //         text: 'change-serial',
    //         active: true,
    //       },
    //     ],
    //   },
    // },
    // {
    //   path: '/admin/clientInformation',
    //   name: 'clientInformation',
    //   component: () => import('@/pages/admin/ClientInforamtion/List.vue'),
    //   meta: {
    //     pageTitle: 'clientInformation',
    //     breadcrumb: [{ text: 'clientInformation', active: true }],
    //     permission: 'viewClientInformation',
    //   },
    // },
    {
      path: '/admin/custom-report',
      name: 'custom-report',
      component: () => import('@/pages/admin/CustomReport/Edit.vue'),
      meta: {
        pageTitle: 'custom-report',
        breadcrumb: [
          {
            text: 'custom-report',
            active: true,
          },
        ],
      },
    },
    {
      path: '/admin/notActive-users',
      name: 'notActive-users',
      component: () => import('@/pages/admin/NotActiveClients/List.vue'),
      meta: {
        pageTitle: 'notActive-users',
        breadcrumb: [
          {
            text: 'notActive-users',
            active: true,
          },
        ],
        permission: 'viewPendingClients',
      },
    },
    // {
    //   path: '/admin/action-plan',
    //   name: 'action-plan',
    //   component: () => import('@/pages/admin/ActionPlans/board.vue'),
    //   meta: {
    //     pageTitle: 'action-plan',
    //     breadcrumb: [{ text: 'action-plan', active: true }],
    //     permission: '',
    //   },
    // },
    // {
    //   path: '/admin/supportTickets',
    //   name: 'supportTickets',
    //   component: () => import('@/pages/admin/supportTickets/List.vue'),
    //   meta: {
    //     pageTitle: 'supportTickets',
    //     breadcrumb: [{ text: 'supportTickets', active: true }],
    //     permission: 'viewTickets',
    //   },
    // },
    // {
    //   path: '/admin/agents',
    //   name: 'agents',
    //   component: () => import('@/pages/admin/Agents/List.vue'),
    //   meta: {
    //     pageTitle: 'agents',
    //     breadcrumb: [{ text: 'agents', active: true }],
    //     permission: '',
    //   },
    // },
    // {
    //   path: '/admin/trainers',
    //   name: 'trainers',
    //   component: () => import('@/pages/admin/Trainers/List.vue'),
    //   meta: {
    //     pageTitle: 'trainers',
    //     breadcrumb: [{ text: 'trainers', active: true }],
    //     permission: '',
    //   },
    // },
    {
      path: '/admin/users',
      component: () => import('@/layouts/navigationIndex.vue'),
      children: [
        {
          path: '',
          name: 'users',
          component: () => import('@/pages/admin/Settings/Users/List.vue'),
          meta: {
            pageTitle: 'users',
            breadcrumb: [{ text: 'Settings', active: true }],
            permission: 'viewUsers',
          },
        },
        {
          path: 'new',
          name: 'new-users',
          component: () => import('@/pages/admin/Settings/Users/Edit.vue'),
          meta: {
            pageTitle: 'newUser',
            breadcrumb: [
              { text: 'Settings', active: true },
              { text: 'users', active: true },
            ],
            permission: 'addUsers',
          },
        },
        {
          path: 'edit/:id',
          name: 'edit-users',
          props: true,
          component: () => import('@/pages/admin/Settings/Users/Edit.vue'),
          meta: {
            pageTitle: 'editUser',
            breadcrumb: [
              { text: 'Settings', active: true },
              { text: 'users', active: true },
            ],
            permission: 'editUsers',
          },
        },
      ],
    },
    {
      path: '/admin/history',
      name: 'history',
      component: () => import('@/pages/admin/HistoryLog/List.vue'),
      meta: {
        pageTitle: 'history',
        breadcrumb: [{ text: 'history', active: true }],
        permission: '',
      },
    },
    // {
    //   path: '/admin/invoices',
    //   name: 'invoices',
    //   component: () => import('@/pages/admin/Invoices/List.vue'),
    //   meta: {
    //     pageTitle: 'invoices',
    //     breadcrumb: [{ text: 'invoices', active: true }],
    //     permission: 'getSalesItemTransactions',
    //   },
    // },
    {
      path: '/admin/fiscalYear',
      name: 'fiscalYear',
      component: () => import('@/pages/admin/FiscalYear/Edit.vue'),
      meta: {
        pageTitle: 'fiscalYear',
        breadcrumb: [{ text: 'fiscalYear', active: true }],
        permission: 'viewHistory',
      },
    },
    {
      path: '/admin/register',
      name: 'adminRegister',
      component: () => import('@/pages/admin/register/Edit.vue'),
      meta: {
        pageTitle: 'adminRegester',
        breadcrumb: [{ text: 'adminRegester', active: true }],
        permission: 'registerNewClient',
      },
    },
    // {
    //   path: '/admin/renewSubscription',
    //   name: 'renewSubscription',
    //   component: () => import('@/pages/admin/RenewClients/Edit.vue'),
    //   meta: {
    //     pageTitle: 'renewSubscription',
    //     breadcrumb: [{ text: 'renewSubscription', active: true }],
    //     permission: 'addClientRenewal',
    //   },
    // },
    // {
    //   path: '/admin/renewClients',
    //   name: 'renewClients',
    //   component: () => import('@/pages/admin/RenewClients/List.vue'),
    //   meta: {
    //     pageTitle: 'renewClients',
    //     breadcrumb: [{ text: 'renewClients', active: true }],
    //     permission: 'viewClientRenewal',
    //   },
    // },
    // {
    //   path: '/admin/renewLicense',
    //   name: 'renewLicense',
    //   component: () => import('@/pages/admin/register/Renew.vue'),
    //   meta: {
    //     pageTitle: 'renewLicense',
    //     breadcrumb: [{ text: 'renewLicense', active: true }],
    //     permission: 'renewLicense',
    //   },
    // },
    {
      path: '/admin/upgradePlan',
      name: 'upgradePlan',
      component: () => import('@/pages/admin/UpgradePlan/Edit.vue'),
      meta: {
        pageTitle: 'upgradePlan',
        breadcrumb: [{ text: 'upgradePlan', active: true }],
        permission: 'editPlan'
      },
    },
    {
      path: '/admin/clientInformation',
      name: 'clientInformation',
      component: () => import('@/pages/admin/ClientInformation/Edit.vue'),
      meta: {
        pageTitle: 'clientInformation',
        breadcrumb: [{ text: 'clientInformation', active: true }],
        permission: 'viewClientInformation'
      }
    },
    {
      path: '/admin/custom-report',
      name: 'custom-report',
      component: () => import('@/pages/admin/CustomReport/Edit.vue'),
      meta: {
        pageTitle: 'custom-report',
        breadcrumb: [
          {
            text: 'custom-report',
            active: true,
          },
        ],
      },
    },
    { path: '*', redirect: 'error-404' },
  ],
});

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn();
  if (prodctionUrl !== -1 && !isLoggedIn && to.name !== 'admin-login') return next({ name: 'admin-login' });
  if ((profile && profile.isAdmin || profile.isSystemUser) && to.name === 'home') return next({ name: 'admin-home' });
  if ((profile && !profile.isAdmin && profile.isSystemUser) && to.name === 'home') return next({ name: 'admin-home' });
  if (profile && profile.isAdmin === undefined && to.name === 'admin-home') return next({ name: 'home' });
  // Redirect to login if not logged in
  if (!isLoggedIn && to.name !== 'register' && to.name !== 'login' && to.name !== 'activate' && to.name !== 'start-calc' && to.name !== 'admin-login') return next({ name: 'login' });
  if (!canNavigate(to)) {
    // If logged in => not authorized
    return next({ name: 'error-404' });
  }
  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData();
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null));
  }

  return next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg');
  if (appLoading) {
    appLoading.style.display = 'none';
  }
});

export default router;
