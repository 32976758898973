<template>
  <b-card>
    <b-card-body class="client-information">

      <!-- filter section  -->
      <b-row class="filter-section mb-2">
        <b-col
          md="12"
          class="d-flex"
        >
          <feather-icon
            icon="BookmarkIcon"
            size="25"
            class="filter-icon"
          />
          <label class="filter-label">
            {{ $t('clientInformationPreview') }}
          </label>
        </b-col>

        <!-- tenant Id -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <g-field
            :value.sync="filter.tenantId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="domain"
            field="select"
            :options="lookups.tenants"
            label="domainName"
            @change="getAndSetBranches()"
          />
        </b-col>

        <!-- branch Id -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <g-field
            :value.sync="filter.branchId"
            :dir="isRight ? 'rtl' : 'ltr'"
            label-text="branch"
            field="select"
            :options="lookups.branches"
            :label="isRight ? 'arabicName' : 'englishName'"
          />
        </b-col>

        <!-- from date -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <label
            class="dateLabel font-small-4"
            for="fromDate-datepicker"
          > {{ $t('fromDate') }}
          </label>
          <b-form-datepicker
            id="fromDate-datepicker"
            v-model="filter.fromDate"
            locale="ar"
            :date-format-options=" {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="fromDate"
          />
        </b-col>

        <!-- to date -->
        <b-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
        >
          <label
            class="dateLabel font-small-4"
            for="toDate-datepicker"
          > {{ $t('toDate') }}
          </label>
          <b-form-datepicker
            id="toDate-datepicker"
            v-model="filter.toDate"
            locale="ar"
            :date-format-options=" {
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            class="mb-2"
            name="toDate"
          />
        </b-col>

        <!-- display button -->
        <b-col md="12">
          <b-button
            variant="relief-primary"
            class="float-right mb-1"
            @click="getData()"
          >
            <feather-icon
              icon="EyeIcon"
              class="mr-50"
            />
            {{ $t('display') }}
          </b-button>
        </b-col>
      </b-row>

      <!-- sales Information -->
      <b-row class="mb-1">
        <b-col
          md="12"
          class="p-0"
        >
          <div class="group-style">
            <b-col class="pt-1">
              <feather-icon
                icon="BookmarkIcon"
                size="25"
                class="filter-icon"
              />
              <label class="filter-label">
                {{ $t('salesInformation') }}
              </label>
            </b-col>

            <b-col class="pb-1">
              <b-row>
                <b-col md="3">
                  <key-value-component
                    :key-name="'totalSalsesCount'"
                    :value="selectedItem.salesCount"
                    :icon="'ShoppingBagIcon'"
                  />
                </b-col>
                <b-col md="3">
                  <key-value-component
                    :key-name="'saleInvoiceLastDate'"
                    :value="selectedItem.lastSalesTransactionDate"
                    :icon="'CalendarIcon'"
                  />
                </b-col>
                <b-col md="3">
                  <key-value-component
                    :key-name="'totalReturnsCount'"
                    :value="selectedItem.returnSalesCount"
                    :icon="'RefreshCwIcon'"
                  />
                </b-col>
                <b-col md="3">
                  <key-value-component
                    :key-name="'returnInvoiceLastDate'"
                    :value="selectedItem.lastReturnSalesTransactionDate"
                    :icon="'CalendarIcon'"
                  />
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <!-- students and employees Information -->
        <b-col
          md="6"
          class="p-0 pr-lg-50"
        >
          <div class="group-style">
            <b-col class="mt-1">
              <feather-icon
                icon="BookmarkIcon"
                size="25"
                class="filter-icon"
              />
              <label class="filter-label">
                {{ $t('studentsAndEmployeesInformation') }}
              </label>
            </b-col>

            <b-col class="pb-1">
              <b-row>
                <b-col md="6">
                  <key-value-component
                    :key-name="'totalStudentsCount'"
                    :value="selectedItem.studentsCount"
                    :icon="'UserIcon'"
                  />
                </b-col>
                <b-col md="6">
                  <key-value-component
                    :key-name="'totalEmployeesCount'"
                    :value="selectedItem.employeesCount"
                    :icon="'BriefcaseIcon'"
                  />
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-col>

        <!-- collection voucher Information -->
        <b-col
          md="6"
          class="p-0 pl-lg-50"
        >
          <div class="group-style">
            <b-col class="mt-1">
              <feather-icon
                icon="BookmarkIcon"
                size="25"
                class="filter-icon"
              />
              <label class="filter-label">
                {{ $t('collectionVoucherInformation') }}
              </label>
            </b-col>

            <b-col class="pb-1">
              <b-row>
                <b-col md="6">
                  <key-value-component
                    :key-name="'totalCollectionsCount'"
                    :value="selectedItem.collectionsCount"
                    :icon="'DollarSignIcon'"
                  />
                </b-col>
                <b-col md="6">
                  <key-value-component
                    :key-name="'collectionLastDate'"
                    :value="selectedItem.lastCollectionDate"
                    :icon="'CalendarIcon'"
                  />
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-col>
      </b-row>

      <b-row class="mb-2">
        <!-- purchase Information -->
        <b-col
          md="6"
          class="p-0 pr-lg-50"
        >
          <div class="group-style">
            <b-col class="mt-1">
              <feather-icon
                icon="BookmarkIcon"
                size="25"
                class="filter-icon"
              />
              <label class="filter-label">
                {{ $t('purchasesInformation') }}
              </label>
            </b-col>

            <b-col class="pb-1">
              <b-row>
                <b-col md="6">
                  <key-value-component
                    :key-name="'totalPurchasesCount'"
                    :value="selectedItem.purchasesCount"
                    :icon="'ShoppingCartIcon'"
                  />
                </b-col>
                <b-col md="6">
                  <key-value-component
                    :key-name="'purchaseInvoiceLastDate'"
                    :value="selectedItem.lastPurchasesTransactionDate"
                    :icon="'CalendarIcon'"
                  />
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-col>

        <!-- dialy voucher Information -->
        <b-col
          md="6"
          class="p-0 pl-lg-50"
        >
          <div class="group-style">
            <b-col class="mt-1">
              <feather-icon
                icon="BookmarkIcon"
                size="25"
                class="filter-icon"
              />
              <label class="filter-label">
                {{ $t('dialyVoucherInformation') }}
              </label>
            </b-col>

            <b-col class="pb-1">
              <b-row>
                <b-col md="6">
                  <key-value-component
                    :key-name="'totalVouchersCount'"
                    :value="selectedItem.vouchersCount"
                    :icon="'CloudIcon'"
                  />
                </b-col>
                <b-col md="6">
                  <key-value-component
                    :key-name="'voucherLastDate'"
                    :value="selectedItem.lastVoucherDate"
                    :icon="'CalendarIcon'"
                  />
                </b-col>
              </b-row>
            </b-col>
          </div>
        </b-col>
      </b-row>

    </b-card-body>
  </b-card>
</template>

<script>
import keyValueComponent from '@/components/custom/keyValueComponent.vue';

export default {
  components: {
    keyValueComponent
  },
  data() {
    return {
      lookups: {
        tenants: [],
        branches: []
      },
      filter: {
        tenantId: null,
        branchId: null,
        fromDate: null,
        toDate: null
      },
      selectedItem: {
        studentsCount: 0,
        employeesCount: 0,
        salesCount: 0,
        returnSalesCount: 0,
        purchasesCount: 0,
        vouchersCount: 0,
        collectionsCount: 0,
        lastSalesTransactionDate: '---',
        lastReturnSalesTransactionDate: '---',
        lastPurchasesTransactionDate: '---',
        lastVoucherDate: '---',
        lastCollectionDate: '---'
      },
    };
  },
  mounted() {
    this.filter.fromDate = this.getDate(new Date(new Date().getFullYear(), 0, 1));
    this.filter.toDate = this.getDate();
    this.getTenants();
  },
  methods: {
    getData() {
      let params = '?';
      params += this.getFilterObj(this.filter);
      this.get({ url: `Clients/clientInformation${params}` }).then((data) => {
        this.selectedItem = data;
        this.prepareDataDates(data);
      });
    },
    getTenants() {
      this.get({ url: 'Tenants' }).then((data) => {
          this.lookups.tenants = data;
      });
    },
    getAndSetBranches() {
      this.get({ url: `Clients/clientBranches/${this.filter.tenantId}` }).then((data) => {
          this.lookups.branches = data;
          if (data) this.filter.branchId = data[0].id;
      });
    },
    prepareDataDates(data) {
        this.selectedItem.lastSalesTransactionDate = data.lastSalesTransactionDate ? this.getDate(data.lastSalesTransactionDate) : '---';
        this.selectedItem.lastReturnSalesTransactionDate = data.lastReturnSalesTransactionDate ? this.getDate(data.lastReturnSalesTransactionDate) : '---';
        this.selectedItem.lastCollectionDate = data.lastCollectionDate ? this.getDate(data.lastCollectionDate) : '---';
        this.selectedItem.lastPurchasesTransactionDate = data.lastPurchasesTransactionDate ? this.getDate(data.lastPurchasesTransactionDate) : '---';
        this.selectedItem.lastVoucherDate = data.lastVoucherDate ? this.getDate(data.lastVoucherDate) : '---';
    }
  },
};
</script>

<style>
.client-information .filter-section {
  background-color: #f9f9f9;
  padding: 25px 0 5px;
  border-radius: 7px;
  border: 2px solid #EEE;
}
.eclient-information .btn-filter {
  margin-left: 14px;
  width: 157px;
  margin-bottom: 15px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 16px;
}

.filter-icon {
  margin-right: 0.5rem;
  color: #7367f0;
}

.filter-label {
  border-bottom: 5px solid #7367f0;
  padding-bottom: 15px;
  margin-bottom: 1.5rem;
  font-weight: bold;
  font-size: 20px;
  color: #7367f0;
}
.group-style {
  background-color: #f9f9f9;
  padding: 5px;
  border: 2px solid #EEE;
}
</style>
