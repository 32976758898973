<template>
  <b-card>
    <b-tabs content-class="mt-2" justified>
      <b-tab :title="$t('mainData')" :disabled="isMainDataDisabled">
        <div class="pt-1">
          <b-row>
            <b-col md="3">
              <!-- StartDate  -->
              <label class="dateLabel" for="example-datepicker">{{
                $t('startDate')
              }}</label>
              <b-form-datepicker
                id="example-datepicker"
                v-model="filter.StartDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                @input="() => refreshItems()"
                class="mb-2"
              ></b-form-datepicker>
            </b-col>
            <b-col md="3">
              <!-- EndDate  -->
              <label class="dateLabel" for="example-datepicker2">{{
                $t('endDate')
              }}</label>
              <b-form-datepicker
                id="example-datepicker2"
                v-model="filter.EndDate"
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                class="mb-2"
                @input="() => refreshItems()"
              ></b-form-datepicker>
            </b-col>
            <b-col md="3">
              <!-- ClientName  -->
              <label class="dateLabel">{{ $t('customerName') }}</label>
              <g-field
                id="customerName"
                :value.sync="filter.ClientName"
                name="ClientName"
                v-silent-search
                @keydown.enter="
                  (v) => {
                    refreshItems();
                  }
                "
              />
            </b-col>
            <b-col md="3">
              <!-- ClientEmail  -->
              <label class="dateLabel">{{ $t('email') }}</label>
              <g-field
                :value.sync="filter.Email"
                name="ClientEmail"
                v-silent-search
                @keydown.enter="
                  (v) => {
                    refreshItems();
                  }
                "
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <!-- ContactPerson  -->
              <label class="dateLabel">{{ $t('contactPerson') }}</label>
              <g-field
                id="contactPerson"
                :value.sync="filter.ContactPerson"
                name="ContactPerson"
                v-silent-search
                @keydown.enter="
                  (v) => {
                    refreshItems();
                  }
                "
              />
            </b-col>
            <b-col md="3">
              <!-- ContactPhone  -->
              <label class="dateLabel">{{ $t('phone') }}</label>
              <g-field
                id="ContactPhone"
                :value.sync="filter.ContactPhone"
                name="ContactPhone"
                v-silent-search
                @keydown.enter="
                  (v) => {
                    refreshItems();
                  }
                "
              />
            </b-col>
            <b-col md="3">
              <!-- DomainName  -->
              <label class="dateLabel">{{ $t('domin') }}</label>
              <g-field
                id="DomainName"
                :value.sync="filter.DomainName"
                name="DomainName"
                v-silent-search
                @keydown.enter="
                  (v) => {
                    refreshItems();
                  }
                "
              />
            </b-col>
            <b-col md="3">
              <!-- ClientAddress  -->
              <label class="dateLabel">{{ $t('address') }}</label>
              <g-field
                id="ClientAddress"
                :value.sync="filter.Address"
                name="ClientAddress"
                v-silent-search
                @keydown.enter="
                  (v) => {
                    refreshItems();
                  }
                "
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <!-- userWillEndSubIn  -->
              <label class="dateLabel">{{ $t('userWillEndSubIn') }}</label>
              <b-form-input
                v-model="filter.SubscriptionEndIn"
                type="number"
                class="mb-1"
                v-silent-search
                @keydown.enter="
                  (v) => {
                    refreshItems();
                  }
                "
              ></b-form-input>
            </b-col>
            <b-col md="3">
              <b-form-group>
                <label class="dateLabel">{{ $t('planName') }}</label>
                <g-field
                  :value.sync="filter.PlanId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  field="select"
                  name="plansCat"
                  :options="plans"
                  @change="() => refreshItems()"
                  label="arabicName"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </div>
        <div class="my-2">
          <div class="row justify-content-between">
            <div class="col-md-4 col-12"></div>
            <div
              class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-md-4 col-12"
            >
              <vue-excel-xlsx
                :data="itemsArray"
                :columns="tableColumns"
                :file-name="'PluginClients'"
                :sheet-name="'xlsxSheet'"
                class="btn btn-relief-success ml-1 p-0 ptn-sm"
              >
                <img
                  src="@/assets/images/icons/xls.jpg"
                  alt=""
                  style="height: 39px; width: auto"
                />
              </vue-excel-xlsx>
              <!-- <b-button
              variant="relief-danger"
              class="ml-1 p-0 ptn-sm"
              @click="printDocument"
            >
              <img src="@/assets/images/icons/pdf.jpg" alt="" style="height: 39px;width: auto;" />
            </b-button> -->
            </div>
          </div>
        </div>
        <g-table
          ref="currentUsers-table"
          :items="itemsSearchProvider"
          :columns="tableColumns"
          :noAction="true"
          perPage="25"
          style="font-size: 14px"
          :totalRows="totalRows"
          :excelButton="{ visiable: false }"
          :pdfButton="{ visiable: false }"
          :createButton="{ visiable: false }"
          :searchInput="{ visiable: false }"
          :tbody-tr-class="
            (item) => {
              if (item && item.endDate < today) return 'bg-light-danger';
            }
          "
        >
        </g-table>
      </b-tab>
      <b-tab :title="$t('changeEndDateSub')" :disabled="isSubscriptionEndDateDisabled">
        <g-form @submit="save">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.tenantId"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="domin"
                  field="select"
                  name="dealer"
                  rules="required"
                  :options="tenants"
                  label="domainName"
                  @input="() => {}"
                  @change="
                    (v) => {
                      getSupscription(v.id);
                      selectedItem.companyName = v.id;
                    }
                  "
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.companyName"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="companyName"
                  field="select"
                  name="companyName"
                  :options="tenants"
                  disabled
                  label="name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <g-picker
                :value.sync="selectedItem.endDate"
                label-text="endDate"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                class="mx-1"
                type="submit"
                variant="primary"
                data-action-type="save"
              >
                {{ $t('save') }}
              </b-button>
            </b-col>
          </b-row>
        </g-form>
      </b-tab>
      <b-tab :title="$t('changeAdminPassword')" :disabled="isAdminPasswordDisabled">
        <!-- <g-form @submit="saveChange"> -->
        <b-row>
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItemPass.tenantId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="domin"
                field="select"
                name="dealer"
                rules="required"
                :options="tenants"
                label="domainName"
                @input="() => {}"
                @change="
                  (v) => {
                    selectedItemPass.companyName = v.id;
                  }
                "
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItemPass.companyName"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="companyName"
                field="select"
                name="companyName"
                :options="tenants"
                disabled
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <g-field
              id="adminName"
              label-text="adminName"
              :value.sync="selectedItemPass.adminUser"
              name="adminName"
            />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItemPass.newPassword"
                type="password"
                label-text="newPassword"
                name="newPassword"
                autocomplete="off"
              />
            </b-form-group>
            <password-validation
              :password="selectedItemPass.newPassword"
              @validPassword="updateValidPassword"
            />
          </b-col>
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="selectedItemPass.passwordConfirmation"
                type="password"
                label-text="confirmPassword"
              />
              <!-- danger -->
              <b-alert
                variant="danger"
                :show="
                  selectedItemPass.passwordConfirmation &&
                    selectedItemPass.passwordConfirmation !==
                      selectedItemPass.newPassword
                "
              >
                <div class="alert-body">
                  <span>{{ $t('passwordDonotMatched') }}</span>
                </div>
              </b-alert>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="12" class="d-flex justify-content-end">
            <b-button
              class="mx-1"
              @click="saveChange"
              :disabled="
                selectedItemPass.passwordConfirmation !==
                  selectedItemPass.newPassword ||
                  !valid_password ||
                  !selectedItemPass.newPassword ||
                  !selectedItemPass.tenantId
              "
              variant="primary"
              data-action-type="save"
            >
              {{ $t('save') }}
            </b-button>
          </b-col>
        </b-row>
        <!-- </g-form> -->
      </b-tab>
      <b-tab :title="$t('transfareAccounts')" :disabled="isTransferAccountsDisabled">
        <g-form @submit="transfareAccounts">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.sourceTenant"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="sourceTenant"
                  field="select"
                  name="tenant"
                  rules="required"
                  :options="tenants"
                  label="domainName"
                  @change="
                    (v) => {
                      currentTenant = v;
                    }
                  "
                  @input="() => {}"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.targetTenant"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="targetTenant"
                  field="select"
                  name="tenant"
                  rules="required"
                  :options="tenants"
                  label="domainName"
                  @change="
                    (v) => {
                      currentTenant = v;
                    }
                  "
                  @input="() => {}"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                class="mx-1"
                type="submit"
                variant="primary"
                data-action-type="transfareAccounts"
              >
                {{ $t('transfareAccounts') }}
              </b-button>
            </b-col>
          </b-row>
        </g-form>
      </b-tab>
      <b-tab :title="$t('activateStore')" :disabled="isActivateStoresDisabled">
        <g-form @submit="storeEnable">
          <b-row>
            <b-col md="4">
              <b-form-group>
                <g-field
                  :value.sync="selectedItem.sourceTenant"
                  :dir="isRight ? 'rtl' : 'ltr'"
                  label-text="domin"
                  field="select"
                  name="tenant"
                  rules="required"
                  :options="tenants"
                  label="domainName"
                  @change="
                    (v) => {
                      currentTenant = v;
                    }
                  "
                  @input="() => {}"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group class="d-inline" :label="$t('isActive')">
                <b-form-checkbox
                  v-model="selectedItem.storeEnabled"
                  class="mr-0 mt-50"
                  name="is-rtl"
                  inline
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <hr />
            </b-col>
            <b-col cols="12" class="d-flex justify-content-end">
              <b-button
                class="mx-1"
                type="submit"
                variant="primary"
                data-action-type="storeEnable"
              >
                {{ $t('save') }}
              </b-button>
            </b-col>
          </b-row>
        </g-form>
      </b-tab>
    </b-tabs>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>

<script>
import GTable from '@/pages/Shared/Table.vue';
import passwordValidation from './components/passwordValidation.vue';

export default {
  components: {
    GTable,
    passwordValidation,
  },
  data() {
    return {
      searchQuery: '',
      currentPage: 1,
      perPage: 25,
      filter: {
        ClientName: '',
        Email: '',
        ContactPerson: '',
        ContactPhone: '',
        DomainName: '',
        Address: '',
        PlanId: '',
        StartDate: '',
        EndDate: '',
        SubscriptionEndIn: '',
      },
      isTableBusy: false,
      totalRows: 0,
      pathName: this.$route.name,
      itemsArray: [],
      plans: [],
      hideActions: false,
      selectedItem: {},
      selectedItemPass: {
        newPassword: '',
      },
      valid_password: false,
      tenants: [],
      tenantData: {},
      subscription: {},
      currentTenant: {},
      profile: JSON.parse(window.localStorage.getItem('@USER_PROFILE'))
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'name',
          field: 'name',
          label: this.$t('customerName'),
          sortable: true,
        },
        {
          key: 'email',
          field: 'email',
          label: this.$t('email'),
          sortable: true,
        },
        {
          key: 'contactPerson',
          field: 'contactPerson',
          label: this.$t('contactPerson'),
          sortable: true,
        },
        {
          key: 'contactPhone',
          field: 'contactPhone',
          label: this.$t('phone'),
          sortable: true,
        },
        {
          key: 'domainName',
          field: 'domainName',
          label: this.$t('domin'),
          sortable: true,
          formatter: (key, value, item) => {
            return `<span class="badge badge-light-primary">${item.domainName}</span>`;
          },
        },
        {
          key: 'address',
          field: 'address',
          label: this.$t('address'),
          sortable: true,
        },
        {
          key: 'planArabicName',
          field: 'planArabicName',
          label: this.$t('plan'),
          sortable: true,
        },
        {
          key: 'startDate',
          field: 'startDate',
          label: this.$t('subscrptionDateStart'),
          sortable: true,
          formatter: (value, key, item) => {
            return this.getDate(item.startDate);
          },
          tdClass: ['text-nowrap'],
        },
        {
          key: 'endDate',
          field: 'endDate',
          label: this.$t('subscrptionDateEnd'),
          sortable: true,
          formatter: (value, key, item) => {
            return this.getDate(item.endDate);
          },
          tdClass: ['text-nowrap'],
        },
        {
          key: 'lastInvoiceDate',
          field: 'lastInvoiceDate',
          label: this.$t('lastInvoiceDate'),
          sortable: true,
          formatter: (value, key, item) => {
            const date = item.lastInvoiceDate || null;
            //  if (date !== null) return this.getDate(date);
            return date;
          },
          tdClass: ['text-nowrap'],
        },
      ];
    },

    isMainDataDisabled() {
      return this.profile.isAdmin ? false : !this.profile.permissions.some(id => id === 'viewCurrentClients')
    },
    isSubscriptionEndDateDisabled() {
      return this.profile.isAdmin ? false : !this.profile.permissions.some(id => id === 'changeSubscriptionEndDate')
    },
    isAdminPasswordDisabled() {
      return this.profile.isAdmin ? false : !this.profile.permissions.some(id => id === 'changeAdminPassword')
    },
    isTransferAccountsDisabled() {
      return this.profile.isAdmin ? false : !this.profile.permissions.some(id => id === 'transferAccounts')
    },
    isActivateStoresDisabled() {
      return this.profile.isAdmin ? false : !this.profile.permissions.some(id => id === 'activateStores')
    }
  },
  watch: {
    'filter.StartDate'(newVal) {
      if (this.filter.EndDate && newVal > this.filter.EndDate) {
        this.doneAlert({
          title: this.$t('startDateShouldBelessThanEndDate'),
          type: 'error',
        });
        return false;
      }
      return true;
    },
    'filter.EndDate'(newVal) {
      if (this.filter.StartDate && this.filter.StartDate > newVal) {
        this.doneAlert({
          title: this.$t('startDateShouldBelessThanEndDate'),
          type: 'error',
        });
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.getPlans();
    this.getTeanets();
  },
  methods: {
    getSupscription(_id) {
      this.get({ url: `Subscriptions?tenantId=${_id}` }).then((data) => {
        this.subscription = data;
        this.selectedItem.endDate = this.getDate(this.subscription.endDate);
      });
    },
    getTeanets() {
      this.get({ url: 'Tenants' }).then((data) => {
        this.tenants = data;
      });
    },
    save() {
      this.update({
        url: `Subscriptions/Change-Subscription-EndDate?tenantId=${this.selectedItem.tenantId}&endDate=${this.selectedItem.endDate}`,
        // data: this.selectedItem,
      }).then(() => {
        this.doneAlert({ text: this.$t('updatedSuccessfully') });
        this.selectedItem = {};
        // this.$router.push({ name: 'admin-home' });
      });
    },
    createSystemUserUser() {
      this.create({
        url: `Tenants/${this.selectedItem.tenantId}/company/systemUser`,
        // data: this.selectedItem,
      }).then(() => {
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        this.selectedItem = {};
        // this.$router.push({ name: 'admin-home' });
      });
    },
    transfareAccounts() {
      this.create({
        url: `Tenants/TransferAccounts/${this.selectedItem.sourceTenant}/${this.selectedItem.targetTenant}`,
        // data: this.selectedItem,
      }).then(() => {
        this.doneAlert({ text: this.$t('savedSuccessfully') });
        this.selectedItem = {};
        // this.$router.push({ name: 'admin-home' });
      });
    },
    storeEnable() {
      this.update({
        url: `Subscriptions/storeEnable?tenantId=${this.selectedItem.sourceTenant}&storeEnabled=${this.selectedItem.storeEnabled}`,
        // data: this.selectedItem,
      }).then(() => {
        this.doneAlert({ text: this.$t('updatedSuccessfully') });
        this.selectedItem = {};
        // this.$router.push({ name: 'admin-home' });
      });
    },
    saveChange() {
      this.update({
        url: 'Tenants/admin-Password',
        data: this.selectedItemPass,
      }).then(() => {
        this.doneAlert({ text: this.$t('updatedSuccessfully') });
        this.selectedItemPass = {};
        // this.$router.push({ name: 'admin-home' });
      });
    },
    updateValidPassword(validPass) {
      this.valid_password = validPass;
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas) {
        var imgData = canvas.toDataURL('image/png');
        var imgWidth = 210;
        var pageHeight = 295;
        var imgHeight = (canvas.height * imgWidth) / canvas.width;
        var heightLeft = imgHeight;

        var doc = new jsPDF('p', 'mm');
        var position = 0;

        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;

        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;
        }
        doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      setTimeout(() => {
        this.pdfExport('Plugin-Clients');
        this.hideActions = false;
      }, 500);
    },
    getPlans() {
      this.get({ url: 'plans' }).then((data) => {
        this.plans = data;
      });
    },
    refreshItems() {
      this.$refs['currentUsers-table'].refreshTable();
    },
    itemsSearchProvider(ctx, callback) {
      const { currentPage, perPage, sortBy, sortDesc } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      let params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `clients${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            this.totalVals += item.debentureValue;
            item.startDate = this.getDate(item.startDate);
            item.endDate = this.getDate(item.endDate);
            item.lastInvoiceDate = item.lastInvoiceDate
              ? this.getDate(item.lastInvoiceDate)
              : null;
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
  },
};
</script>

<style>
.canseledVouchers {
  text-decoration: line-through;
  color: firebrick;
}
</style>
