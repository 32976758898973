<template>
  <b-card class="m-auto w-50">
    <g-form @submit="save(selectedItem)">
      <b-tabs content-class="mt-3" pills justified>
        <b-tab
          :title="
            $t('renewLincesForOneYear', {
              days: selectedItem.licenseDuration,
            })
          "
          active
        >
          <b-row>
            <b-col md="6">
              <g-field
                :value.sync="selectedDomain"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="domin"
                field="select"
                name="domain"
                :options="tenants"
                label="domainName"
                @input="() => {}"
                @change="
                  (v) => {
                    selectedItem.domainName = v.domainName;
                  }
                "
              />
            </b-col>
            <b-col md="3">
              <b-form-group>
                <!-- users  -->
                <g-field
                  id="users"
                  :value.sync="selectedItem.licenseDuration"
                  label-text="licenseTerm"
                  name="maxCountOfUsage"
                  rules="integer"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col md="3"></b-col>
            <b-col md="6" class="text-center">
              <!-- submit buttons -->
              <b-button @click="renewLicense" variant="dark">
                {{
                  this.$t('renewLincesForOneYear', {
                    days: selectedItem.licenseDuration,
                  })
                }}
              </b-button>
            </b-col>
            <b-col md="3"></b-col>
          </b-row>
        </b-tab>
      </b-tabs>
    </g-form>
  </b-card>
</template>

<script>
export default {
  data() {
    return {
      selectedItem: {
        domainName: '',
        licenseDuration: 365,
      },
      tenants: [],
      selectedDomain: '',
    };
  },
  mounted() {
    this.getTeanets();
  },
  methods: {
    getTeanets() {
      this.get({ url: 'Tenants' }).then((data) => {
        this.tenants = data;
      });
    },
    renewLicense() {
      this.update({
        url: 'Subscriptions/renew-subscription',
        id: `${this.selectedItem.domainName}/${this.selectedItem.licenseDuration}`,
      }).then(() => {
        this.doneAlert({ text: this.$t('updatedSuccessfully') });
        window.location.reload();
      });
    },
  },
};
</script>
