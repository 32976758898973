<template>
  <b-card>
    <!-- <g-form @submit="save"> -->
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-center mb-2"
      >
        <!-- media -->
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                height="80"
                width="80"
                :src="base64Image || imageUrl"
              />
            </b-link>
            <!--/ avatar -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- upload button -->
            <b-button
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t('change') }}
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="image"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
            />
            <!--/ upload button -->
          </b-media-body>
        </b-media>
        <!--/ media -->
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group>
          <g-field
            :value.sync="selectedItem.code"
            label-text="code"
            :disabled="id > 0"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field
            :value.sync="selectedItem.fullName"
            label-text="fullName"
            rules="required"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field
            ref="userName"
            :value.sync="selectedItem.userName"
            label-text="userName"
            rules="required|userName"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field
            :value.sync="selectedItem.passwordHash"
            type="password"
            label-text="password"
            rules="required"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field
            :value.sync="passwordHashConfirm"
            type="password"
            label-text="confirmPassword"
          />
          <!-- danger -->
          <b-alert
            variant="danger"
            :show="passwordHashConfirm !== selectedItem.passwordHash"
          >
            <div class="alert-body">
              <span>{{ $t('passwordDonotMatched') }}</span>
            </div>
          </b-alert>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field
            :value.sync="selectedItem.email"
            label-text="email"
            rules="email"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group>
          <g-field
            :value.sync="selectedItem.phone"
            label-text="phone"
          />
        </b-form-group>
      </b-col>
      <b-form-checkbox
        v-model="selectedItem.isAdmin"
        class="custom-control-primary mt-3"
        @change="(v) => {
          if(v === true){
            selectedItem.permissions = [];
          }
        }"
      >
        {{ $t('anAdmin') }}
      </b-form-checkbox>
      <b-form-checkbox
        v-model="selectedItem.isActive"
        class="custom-control-primary mt-3 mx-2"
      >
        {{ $t('isActive') }}
      </b-form-checkbox>
    </b-row>
    <b-row>
      <b-col
        v-show="!selectedItem.isAdmin"
        cols="12"
        md="6"
        class="medium-window"
      >
        <permissions
          ref="perComponent"
          :key="selectedItem.id"
          :user-permissions="selectedItem.permissions"
          @update-items="
            (val) => {
              items = val;
            }
          "
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-end sticky-bottom"
      >
        <b-button
          v-permission="$route.meta.permission"
          class="mx-1"
          variant="primary"
          data-action-type="save"
          :disabled="
            passwordHashConfirm !== selectedItem.passwordHash ||
              !new RegExp('^[a-zA-Z0-9_]+$').test(selectedItem.userName)
          "
          @click="save"
        >
          {{ $t("save") }}
        </b-button>
      </b-col>
    </b-row>
    <!-- </g-form> -->
  </b-card>
</template>

<script>
import permissions from './components/Permissions.vue';

export default {
  components: {
    permissions,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      passwordHashConfirm: '',
      image: null,
      imageUrl: '',
      base64Image: '',
      selectedItem: {
        userName: '',
        isAdmin: false,
        isActive: true,
        permissions: [],
      },
    };
  },
  computed: {},
  watch: {
    image(newVal) {
      this.selectedItem.imageExtension = newVal.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(newVal);
      reader.onload = () => {
        this.selectedItem.base64Image = reader.result.split(',').pop();
        this.base64Image = reader.result;
      };
    },
  },
  mounted() {
      if (this.id > 0) {
        this.getData();
      }
  },
  methods: {
    getData() {
      this.get({ url: 'User', id: this.id }).then((data) => {
        this.selectedItem = JSON.parse(JSON.stringify(data));
        this.passwordHashConfirm = this.selectedItem.passwordHash;
      });
    },
    save() {
        if (!this.selectedItem.isAdmin) {
          this.selectedItem.permissions = this.items.reduce(
            (groupPermissions, permission) => {
              groupPermissions.push(
                ...permission.children
                  .filter((p) => p.selected)
                  .map((p) => ({
                    userId: this.id || 0,
                    permissionId: p.id,
                  }))
              );
              return groupPermissions;
            },
            []
          );
        } else {
          this.selectedItem.permissions = [];
        }
        (this.id > 0
          ? this.update({
            url: 'User',
            id: this.selectedItem.id,
            data: this.selectedItem,
          })
          : this.create({ url: 'User/add', data: this.selectedItem })
        ).then(() => {
          this.doneAlert({
            text:
              this.id > 0
                ? this.$t('updatedSuccessfully')
                : this.$t('savedSuccessfully'),
          });
          this.$router.push({ name: 'users' });
        });
    },
  },
};
</script>

<style></style>
