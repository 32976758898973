<template>
  <b-card>
    <div>
      <b-row>
        <!-- dealerId  -->
        <b-col md="2">
          <b-form-group>
            <g-field
              :value.sync="filter.TransactionType"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="transactionType"
              field="select"
              name="transactionType"
              :options="invoicesTypes"
              label="arabicName"
              @input="() => {
                $refs['items-table'].refreshTable();
              }"
              @change="() => {
              $refs['items-table'].refreshTable();
              }"
            />
          </b-form-group>
        </b-col>
        <!-- TenantId  -->
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="filter.TenantId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="tenants"
              field="select"
              name="dealer"
              :options="tenants"
              label="name"
              @input="() => {
                $refs['items-table'].refreshTable();
              }"
              @change="() => {
              $refs['items-table'].refreshTable();
              }"
            />
          </b-form-group>
        </b-col>
        <!-- dealerId  -->
        <!-- <b-col md="2">
          <b-form-group>
            <g-field
              :value.sync="filter.UserId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="users"
              field="select"
              name="dealer"
              :options="users"
              label="fullName"
              @input="() => {
                $refs['items-table'].refreshTable();
              }"
              @change="() => {
              $refs['items-table'].refreshTable();
              }"
            />
          </b-form-group>
        </b-col> -->
        <b-col md="2">
          <b-form-group>
            <g-field
              :value.sync="filter.Operation"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="effect"
              field="select"
              name="agent"
              :options="transactionsEffect"
              @input="() => {
                $refs['items-table'].refreshTable();
              }"
              @change="() => {
              $refs['items-table'].refreshTable();
              }"
              label="arabicName"
            />
          </b-form-group>
        </b-col>
          <b-col md="2">
          <!-- fromDate  -->
          <label class="dateLabel" for="example-datepicker">{{
            $t('fromDate')
          }}</label>
          <b-form-datepicker
            id="example-datepicker"
            locale="ar"
            v-model="filter.FromDate"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            @input="() => {
              $refs['items-table'].refreshTable();
            }"
            class="mb-2"
            name="fromDate"
          ></b-form-datepicker>
        </b-col>
        <b-col md="2">
          <!-- toDate  -->
          <label class="dateLabel" for="example-datepicker">{{
            $t('toDate')
          }}</label>
          <b-form-datepicker
            id="example-datepicker"
            locale="ar"
            v-model="filter.ToDate"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
            @input="() => {
              $refs['items-table'].refreshTable();
            }"
            class="mb-2"
            name="toDate"
          ></b-form-datepicker>
        </b-col>
      </b-row>
    </div>
    <b-modal
      no-close-on-backdrop
      ref="edit-modal"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      hide-footer
      :title="$t('restoreInvoices')"
    >
      <g-form @submit="getFile(restoreData)">
        <b-row>
          <b-col md="4">
            <b-form-group>
              <g-field
                :value.sync="restoreData.tenantId"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="domin"
                field="select"
                name="dealer"
                rules="required"
                :options="tenants"
                label="domainName"
                @input="() => {}"
                @change="(v) => {
                  restoreData.companyName = v.id
                }"
              />
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group>
              <g-field
                :value.sync="restoreData.companyName"
                :dir="isRight ? 'rtl' : 'ltr'"
                label-text="companyName"
                field="select"
                name="companyName"
                :options="tenants"
                disabled
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <g-picker
              :value.sync="restoreData.fromDate"
              label-text="fromDate"
            />
          </b-col>
          <b-col md="6">
            <g-picker
              :value.sync="restoreData.toDate"
              label-text="toDate"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
          <div>
            <!-- <input type="file" class="excel-upload-input" @change="handleFileUpload( $event )" accept=".xlsx, .xls"/> -->
            <b-alert
              v-if="readFile"
              show
              fade
              class="mt-2 text-center"
              variant="success"
            >
            <div class="alert-body" style="direction: ltr;height: 20rem;
            overflow: auto;">
                <vue-json-pretty :data="readFile" style="height: inherit; max-height: inherit;"
                :deep-collapse-children="true"
                :show-double-quotes="false"
                :virtual="false"
                :virtual-lines="100"
                :select-on-click-node="false"
                :highlight-selected-node="false" />
              </div>
            </b-alert>
          </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <hr />
          </b-col>
          <b-col cols="4" class="d-flex justify-content-start">
            <b-button class="btn-block" type="submit" variant="primary" data-action-type="save">
              {{ $t('getFile') }}
            </b-button>
          </b-col>
          <b-col cols="8" class="d-flex justify-content-end">
            <b-button class="mx-1" @click="downloadFile" :disabled="!fileData" variant="primary" data-action-type="save">
              {{ $t('downloadFile') }}
            </b-button>
            <b-button class="mx-1" variant="secondary" @click="closeModal">
              {{ $t('Close') }}
            </b-button>
          </b-col>
        </b-row>
      </g-form>
    </b-modal>
    <b-row>
      <b-col md="10" sm="12" class="pt-2">
        <b-button
        variant="warning"
        @click="openModal()"
      >
        {{ $t('restoreInvoices') }}
      </b-button>
      </b-col>
      <b-col md="2" sm="12" class="text-right pt-2">
          <div class="d-flex align-items-center justify-content-end mb-1 mb-md-0 col-12">
            <vue-excel-xlsx
              :data="itemsArray"
              :columns="tableColumns"
              :filename="this.$route.name"
              :sheetname="'xlsxSheet'"
              class="btn btn-relief-success ml-1 p-0 ptn-sm"
              >
              <img src="@/assets/images/icons/xls.jpg" alt="" style="height: 39px;width: auto;" />
          </vue-excel-xlsx>
          <b-button
              variant="relief-danger"
              class="ml-1 p-0 ptn-sm"
              @click="printDocument"
            >
              <img src="@/assets/images/icons/pdf.jpg" alt="" style="height: 39px;width: auto;" />
            </b-button>
          </div>
      </b-col>
    </b-row>
    <div class="my-2">
    </div>
    <g-table
      ref="items-table"
      :items="invoiceProvider"
      :columns="tableColumns"
      foot-clone
      :is-busy="isTableBusy"
      :noAction="true"
      perPage="25"
      :totalRows="totalRows"
      :createButton="{ visiable: false }"
      :excelButton="{ visiable: false }"
      :pdfButton="{ visiable: false }"
      :searchInput="{ visiable: false }"
    >
    <template #actions="{ item }"  v-if="hideActions === false">
        <div class="text-nowrap">
             <feather-icon
            :id="`invoice-row-${item.id}-prev-icon`"
            icon="EyeIcon"
            data-action-type="preview"
            class="mx-1 clickable"
            :hidden="true"
            @click=" () => { editItem(item) }"
            />
          <b-tooltip
            :title="$t('preview')"
            placement="bottom"
            :target="`invoice-row-${item.id}-prev-icon`"
          />
            <b-button
                v-b-tooltip.hover.top="$t('details')"
                variant="flat-warning"
                size="sm"
                @click="(v) => {
                  selectedItem = {...item};
                  selectedItem.details = item.details
                  toggleDetails(item)}"
              >
                <feather-icon
                  icon="InfoIcon"
                  class="mx-0 clickable warning"
                  :id="`invoice-row-${item.id}-info-icon`"
                />
              </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.min.js"></script>
<script src="@/pages/Shared/html2canvas.js"></script>
<script>
import { logsTransactionTypes, transactionsEffect} from '@/libs/acl/Lookups';
import GTable from '@/pages/Shared/Table.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';
import { forEach } from 'postcss-rtl/lib/affected-props';

export default {
  components: {
    GTable,
    VueJsonPretty
  },
  data() {
    return {
      filter: {
        TransactionType: '',
        Operation: '',
        UserId: null,
        FromDate: null,
        ToDate: null,
      },
      selectedItem: {
        details: []
      },
      restoreData:{},
      isTableBusy: false,
      items: [],
      totalRows: 0,
      totalRowsDetails: 0,
      transactionsEffect: transactionsEffect,
      invoicesTypes: logsTransactionTypes,
      tenants: [],
      itemsArray: [],
      hideActions: false,
      currentItems: [],
      fileData: null,
      readFile: null,
    };
  },
  computed: {
    transactionTypesOptions() {
      return this.invoicesTypes.map((item) => {
        return {
          text: this.isRight ? item.arabicName : item.englishName,
          value: item.id,
        }
      });
    },
    tableColumns() {
      return [
        {
          key: 'code',
          field: 'code',
          label: this.$t('code'),
          sortable: true,
        },
        {
          key: 'method',
          field: 'method',
          label: this.$t('effect'),
          sortable: true,
          formatter: (key, value, item) => {
           if (item.method === 'POST') return `<span class="badge badge-primary">${this.$t('added')}</span>`;
           if (item.method === 'DELETE') return `<span class="badge badge-danger">${this.$t('delete')}</span>`
           if (item.method === 'PUT') return `<span class="badge badge-warning">${this.$t('modified')}</span>`
          },
        },
        {
          key: 'transactionTypeArabicName',
          field: 'transactionTypeArabicName',
          label: this.$t('transactionType'),
          sortable: true,
        },
        {
          key: 'userName',
          field: 'userName',
          label: this.$t('userName'),
          sortable: true,
        },
        {
          key: 'timestamp',
          field: 'timestamp',
          label: this.$t('transactionDate'),
          sortable: true,
        },
        {
          key: 'tenantName',
          field: 'tenantName',
          label: this.$t('tenatName'),
          sortable: true,
        },
        {
          key: 'actions'
        },
      ];
    },
  },
  beforeMount() {
    this.getTeanets();
  },
  mounted() {},
  methods: {
    downloadFile() {
      const jsonData = JSON.stringify(this.fileData);
          const blob = new Blob([jsonData], { type: 'application/json' });
          const url = URL.createObjectURL(blob);

          const link = document.createElement('a');
          link.href = url;
          link.download = 'data.json';

          link.click();

          URL.revokeObjectURL(url);
    },
    getFile(item) {
      this.get({ url: `AuditTrail/deletedItemTransactions/json?TenantId=${item.tenantId}&FromDate=${item.fromDate}&ToDate=${item.toDate}` }).then((data) => {
          this.fileData = data;
          this.readFile = data.slice(Math.max(data.length - 5, 1))
      });
    },
    closeModal() {
      this.$refs['edit-modal'].hide();
      this.file.name = '';
    },
    openModal() {
      this.$refs['edit-modal'].show();
    },
    toggleDetails(row) {
      if (row._showDetails) {
        this.$set(row, '_showDetails', false);
        this.detailsShowing = false
      } else {
        this.currentItems.forEach((item) => {
          this.$set(item, '_showDetails', false);
          this.detailsShowing = false
        });

        this.$nextTick(() => {
          this.$set(row, '_showDetails', true);
          this.detailsShowing = true
        });
      }
    },
    pdfExportDetails(name) {
      html2canvas(document.getElementById('mainDetailsTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocumentDetails() {
      this.hide()
        setTimeout(() => {this.pdfExportDetails(this.$route.name)
        this.hideActions = false
        }, 500);
    },
    hide() {
      this.hideActions = true
    },
    pdfExport(name) {
      html2canvas(document.getElementById('mainTable')).then(function(canvas){
      var imgData = canvas.toDataURL('image/png');
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      var doc = new jsPDF('p', 'mm');
      var position = 0;

      doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
          doc.save(`${name}.pdf`);
      });
    },
    printDocument() {
      this.hide()
        setTimeout(() => {this.pdfExport(this.$route.name)
        this.hideActions = false
        }, 500);
    },
    addTdClass(value, key, item) {
          if (this.filter.transactionType === 'quotation') {
            return 'd-none'
          } if (item.transactionType === 'quotation') {
          return 'not-visible';
        }
          return null
      },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: `ItemTransactions/${item.transactionType}`, id: item.id }).then(() => {
            this.doneAlert({ text: this.$t('deletedSuccessfully') });
            this.$refs['items-table'].refreshTable();
          });
        }
      );
    },
    getTeanets() {
      this.get({ url: 'Tenants' }).then((data) => {
          this.tenants = data;
      });
    },
    invoiceProvider(ctx, callback) {
      const {
        currentPage, perPage, sortBy, sortDesc
      } = ctx;

      this.filter.OrderClause = this.orderQuery(sortBy, sortDesc);
      var params = `?pageNumber=${currentPage}&pageSize=${perPage}&`;

      params += this.getFilterObj(this.filter);

      this.isTableBusy = true;
      this.get({ url: `AuditTrail${params}` })
        .then(({ data, totalCount }) => {
          this.isTableBusy = false;
          this.totalRows = totalCount;
          data.forEach((item) => {
            //  if (item.changeSetEntries[0].transactionType !== '') {
              item.changeSetEntries.forEach((ele) => {
                // if (ele.changeSetModifiedProperties === null) {
                //   ele.changeSetModifiedProperties = []
                // }
                if(ele.transactionType !== '') {
                  let transList = ele.transactionType !== '' ? this.invoicesTypes.filter((i) => i.id === ele.transactionType) : [];
              item.transactionType = item.changeSetEntries[0].transactionType || null;
              item.transactionTypeArabicName = transList.length > 0 ? transList[0].arabicName : '';
            // item.code = item.changeSetEntries[0].entityCode;
              if (ele.entityCode != '') {
                item.code = ele.entityCode
              }
              item.changeSetEntries.forEach((itm) => {
                itm.transName = item.transactionTypeArabicName;
              })
            }
              })
            item.timestamp = this.getDate(item.timestamp, true);
          });
          callback(data);
          this.itemsArray = data;
        })
        .catch(() => {
          this.isTableBusy = false;
          callback([]);
        });
      return null;
    },
  },
};
</script>
<style>
.not-visible {
  visibility: hidden;
}
.filter-buttons label{
  padding: 0.75rem !important;
}
</style>
