import permissions from './permissionLookups';

const plans = [
  {
    id: 1,
    arabicName: 'الباقةالأساسية',
    englishName: 'basic Plan',
  },
  {
    id: 2,
    arabicName: 'الباقة الشاملة',
    englishName: 'comprehensive plan',
  }
];
const logsTransactionTypes = [
  {
    id: 'Sales',
    arabicName: 'فاتورة مبيعات',
    englishName: 'POS Invoice',
  },
  {
    id: 'SalesOrder',
    arabicName: 'أمر بيع',
    englishName: 'Sales Order',
  },
  {
    id: 'Quotation',
    arabicName: 'عرض سعر',
    englishName: 'quotation',
  },
  {
    id: 'ReturnSales',
    arabicName: 'مرتجع مبيعات',
    englishName: 'Return Sales',
  },
  {
    id: 'Contract',
    arabicName: 'العقود',
    englishName: 'Contracts',
  },
  {
    id: 'Purch',
    arabicName: 'فاتورة مشتريات',
    englishName: 'Purch Invoice',
  },
  {
    id: 'PurchOrder',
    arabicName: 'أمر شراء',
    englishName: 'Purch Order',
  },
  {
    id: 'ReturnPurch',
    arabicName: 'مرتجع مشتريات',
    englishName: 'Return Sales',
  },
  {
    id: 'ExchangeOrder',
    arabicName: 'اذن صرف',
    englishName: 'ExchangeOrders',
  },
  {
    id: 'AdditionOrder',
    arabicName: 'اذن استلام',
    englishName: 'AdditionOrders',
  },
  {
    id: 'ShortageAdjustment',
    arabicName: 'العجز',
    englishName: 'ShortageAdjustments',
  },
  {
    id: 'OverflowAdjustment',
    arabicName: 'الفائض',
    englishName: 'OverflowAdjustments',
  },
  {
    id: 'DamageAdjustment',
    arabicName: 'التوالف',
    englishName: 'DamageAdjustments',
  },
  {
    id: 'StorageTransfer',
    arabicName: 'التحويل بين المخازن',
    englishName: 'StorageTransfers',
  },
  {
    id: 'Stocktaking',
    arabicName: 'محضر الجرد',
    englishName: 'Stocktakings',
  },
  {
    id: 'Collections',
    arabicName: 'سند صرف مورد',
    englishName: 'Collections',
  },
  {
    id: 'Payments',
    arabicName: 'سند قبض عميل',
    englishName: 'Payments',
  },
  {
    id: '',
    arabicName: 'الكل',
    englishName: 'All',
  },
];

const transactionsEffect = [
  {
    id: 'added',
    englishName: 'save',
    arabicName: 'حفظ',
  },
  {
    id: 'modified',
    englishName: 'edit',
    arabicName: 'تعديل',
  },
  {
    id: 'deleted',
    englishName: 'delete',
    arabicName: 'حذف',
  }
];

export {
  permissions,
  plans,
  logsTransactionTypes,
  transactionsEffect
};
