<template>
  <b-card>
    <g-table
      :columns="tableColumns"
      :items="items"
      class="imageTable"
      :total-rows="totalRows"
      @on-create="
        () => {
          $router.push({ name: 'new-users' });
        }
      "
    >
      <template #fullName="{ item }">
        <b-avatar
          size="40"
          :src="`${baseUrl}${item.imageUrl}`"
          :text="item.imageUrl"
          variant="primary"
          class="mr-50"
        /> {{ item.fullName }}
      </template>
      <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
            v-b-tooltip.hover.top="$t('edit')"
            v-permission=" 'editUsers'"
            data-action-type="edit"
            variant="custom"
            class="btn-icon"
            size="sm"
            @click="edit(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-preview-icon`"
              icon="EditIcon"
              class="ml-1 clickable"
            />
          </b-button>
          <b-button
            v-b-tooltip.hover.top="$t('delete')"
            v-permission=" 'deleteUsers'"
            data-action-type="delete"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            @click="remove(item)"
          >
            <feather-icon
              :id="`invoice-row-${item.id}-delete-icon`"
              icon="TrashIcon"
              stroke="red"
              class="danger"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      items: [],
      totalRows: 0,
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          label: this.$t('code'),
        },
        {
          key: 'fullName',
          label: this.$t('fullName'),
          sortable: true,
          tdClass: 'align-content-center',
        },
        {
          key: 'userName',
          label: this.$t('userName'),
          sortable: true,
        },
        {
          key: 'isAdmin',
          label: this.$t('job'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.isAdmin) return `<span class="badge badge-light-primary p-50">${this.$t('anAdmin')}</span>`;
            return `<span class="badge badge-light-warning p-50">${this.$t('aUser')}</span>`;
          },
        },
        {
          key: 'isActive',
          label: this.$t('status'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.isActive) return `<span class="badge badge-light-success p-50">${this.$t('activated')}</span>`;
            return `<span class="badge badge-light-danger p-50">${this.$t('notActivated')}</span>`;
          },
        },
        {
          key: 'actions',
        },
      ];
    },
  },
  mounted() {
    this.get({ url: 'User' }).then((data) => {
      this.items = data;
      this.totalRows = data.length
    }).catch((data) => {
        if (data.response.status === 403) {
          this.logout();
        }
     });
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
     edit(item) {
      this.$router.push({
        name: 'edit-users',
        params: { id: item.id },
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'user', id: item.id })
            .then(() => {
              this.doneAlert({ text: this.$t('deletedSuccessfully') });
              this.items = this.items.filter((u) => u.id !== item.id);
            })
            .catch((data) => {
              this.doneAlert({ text: data.data.detail, type: 'error' });
            });
        }
      );
    },
  },
};
</script>

<style>
.imageTable .table td, .imageTable .table th{
  text-align: left !important;
}
</style>
