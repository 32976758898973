<template>
  <b-card class="border border-primary">
    <b-row>
      <b-col
        md="6"
        class="mb-1 ml-1 pl-2"
      >
        <!-- <b-form-group> -->
        <b-form-checkbox
          v-model="selectAll"
          class="custom-control-primary"
          @change="toggleSelect(currentBranch)"
        >
          {{ $t('selectAll') }}
        </b-form-checkbox>
        <!-- </b-form-group> -->
      </b-col>
      <b-col md="86" />
    </b-row>
    <v-jstree
      :data="items"
      item-key="title"
      show-checkbox
      multiple
      allow-batch
      whole-row
      :draggable="false"
    >
      <template slot-scope="_">
        <div
          style="display: inherit; width: 400px"
          :class="{'bg-light-primary': _.model.type === 'reports', 'bg-light-warning': _.model.type === 'mobile'}"
          @click.ctrl="itemCtrlClick(_.vm, $event)"
          @click.exact="itemClick(_.vm, $event)"
          @contextmenu.prevent="$refs.ctx.open($event, _.vm)"
        >
          <i
            v-if="!_.model.loading"
            :class="_.vm.themeIconClasses"
            role="presentation"
          />
          {{ _.model.text }}
        </div>
      </template>
    </v-jstree>
  </b-card>
</template>

<script>
import VJstree from 'vue-jstree';
import { permissions as permissionLookups } from '@/libs/acl/Lookups';

export default {
  components: {
    VJstree,
    // VTreeview,
  },
  props: {
    userPermissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      active: [],
      items: [],
      permissionLookups,
      selectAll: false,
    };
  },
  mounted() {
    this.items = this.permissionLookups.filter((p) => p.roles).map((permission) => {
      const childrens = permission.roles.map((role) => {
        return {
          text: this.isRight ? role.arabicName : role.englishName,
          id: role.id,
          type: role.type,
          title: role.id,
          selected: this.userPermissions.some(
            (v) => role.id === v.permissionId
          ),
        };
      });
      return {
        children: childrens,
        opened: false,
        type: permission.type,
        selected:
          childrens.filter((c) => c.selected).length,
        text: this.$t(`${permission.title}`),
        title: permission.name,
        id: permission.name,
      };
    });
    this.$emit('update-items', this.items);
  },
  methods: {
    toggleSelect() {
      this.items.forEach(element => {
        element.selected = this.selectAll
        element.children.forEach(item => {
          item.selected = this.selectAll
        });
      });
    },
  }
};
</script>

<style></style>
