<template>
  <div
    class="keyValue-component"
    :style="{borderColor: backColor }"
  >
    <div>
      <feather-icon
        :icon="icon"
        size="25"
        class="feather"
        :style="{backgroundColor: backColor}"
      />
      <label class="key"> {{ $t(keyName) }}  </label>
    </div>
    <label
      class="value"
      :style="{backgroundColor: backColor}"
    > {{ value }} </label>
  </div>
</template>

<script>
export default {
  props: {
    keyName: {
      type: String,
      default: '',
      required: true
    },
    value: {
      type: String,
      default: '',
      required: true
    },
    backColor: {
      type: String,
      default: '#c6c6fb'
    },
    icon: {
      type: String,
      default: 'TriangleIcon'
    }
  }
}
</script>

<style>
.keyValue-component {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.5rem;
  margin: 5px 0;
  border-radius: 5px;
  border: 2px solid;
}

.keyValue-component .feather {
  margin-right: 0.25rem;
  border: 1px solid;
  border-radius: 50%;
  padding: 4px;
  min-width: 25px;
  margin-right: 10px;
}

.keyValue-component .key {
  font-weight: bolder;
  font-size: 18px;
}

.keyValue-component .value {
  text-align: center;
  font-weight: bolder;
  font-size: 16px;
  margin-top: 0.5rem;
  padding: 5px;
  border-radius: 5px;
  width: 100%;
}
</style>
