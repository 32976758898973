//  viewAssets, addAssets, editAssets, deleteAssets,
// viewQuotations, addQuotations, editQuotations, deleteQuotations,
// viewSalesOrders, addSalesOrders, editSalesOrders, deleteSalesOrders,
// addSalesWithRemainder, modifySalesPrice, viewReturnSalesTransactions, addReturnSalesTransactions, editReturnSalesTransactions, deleteReturnSalesTransactions, viewPurchOrders, addPurchOrders, editPurchOrders, deletePurchOrders, viewPurchTransactions, addPurchTransactions, editPurchTransactions, deletePurchTransactions, viewReturnPurchTransactions, addReturnPurchTransactions, editReturnPurchTransactions, deleteReturnPurchTransactions, viewAdditionOrders, addAdditionOrders, editAdditionOrders, deleteAdditionOrders ]
export default [
    {
      title: 'admin-home',
      route: 'admin-home',
      icon: 'HomeIcon',
    },
    {
      title: 'current-users',
      route: 'current-users',
      icon: 'UsersIcon',
      permission: 'viewCurrentClients'
    },
    {
      title: 'notActive-users',
      route: 'notActive-users',
      icon: 'UserXIcon',
      permission: 'viewPendingClients'
    },
    {
      title: 'pricing-policy',
      route: 'pricing-policy',
      icon: 'DollarSignIcon',
      permission: 'viewPricingPolicy'
    },
    {
      title: 'renewLicense',
      route: 'renewLicense',
      permission: 'renewLicenses',
      icon: 'DatabaseIcon',
    },
    {
      title: 'plans',
      route: 'plans',
      icon: 'UploadCloudIcon',
      permission: 'viewPlans'
    },
    {
      title: 'clientInformation',
      route: 'clientInformation',
      permission: 'viewClientInformation',
      icon: 'UserIcon',
    },
    {
      title: 'upgradePlan',
      route: 'upgradePlan',
      icon: 'GridIcon',
      permission: 'editPlan'
    },
    {
      title: 'discount-code',
      route: 'discount-code',
      icon: 'AwardIcon',
      permission: 'viewDiscountCode'
    },
    {
      title: 'fiscalYear',
      route: 'fiscalYear',
      icon: 'CalendarIcon',
      permission: 'editFiscalYear'
    },
    {
      title: 'adminRegester',
      route: 'adminRegister',
      icon: 'DatabaseIcon',
      permission: 'registerNewClient'
    },
    {
      title: 'Settings',
      icon: 'SettingsIcon',
      children: [
        {
          title: 'users',
          route: 'users',
          permission: 'viewUsers'
        },
        // {
        //   title: 'sms-config',
        //   route: 'sms-config',
        //   permission: 'viewSmsConfig'
        // },
        // {
        //   title: 'change-serial',
        //   route: 'change-serial',
        //   permission: 'changeTransactionSerial'
        // },
        {
          title: 'custom-report',
          route: 'custom-report',
          permission: 'addCustomReports'
        },
      ],
    },
    // {
    //   title: 'history',
    //   route: 'history',
    //   icon: 'ListIcon',
    // },
  ];
